import React, { useContext, useState, useEffect } from "react"
import { 
    Page, 
    Layout, 
    Card,
    Icon,
    Stack,
    Heading,
    Subheading,
    TextStyle,
    DescriptionList
} from "@shopify/polaris"
import styled from 'styled-components'
import { doc, collection, orderBy, query } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseContext from '../../../providers/firebase';
import { CreditCardMajor,TickMinor } from '@shopify/polaris-icons';
import formatCurrency from '../../../helpers/formatCurrency';
const BillingWrapper = styled.div`
    .plan-details {
        text-align: center;
        .plan-name: {
            margin-bottom: 20px;
        }
    }
    .plan-note {
        padding: 20px 0;
        span {
            font-size: 10px;
            color: #8C9196;
        }
    }
    .all-plans-include {
        list-style: none;
        padding-left: 0;
        svg {
            fill: #008060;
        }
        li {
            padding-bottom:5px;
        }
    }
    .metrics {
        margin-top: 10px;
        .Polaris-DescriptionList__Term{
            flex: 0 1 30%;
        }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term {
            border-top: none;
        }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
            border-top: none;
        }
        .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
            padding: 0.4rem 0.8rem 0.4rem 0;
        }
        .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
            padding: 0.4rem 0;
        }
    }
`
function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function revenueToPlan(revenue) {
    let plan
    switch (true) {
        case (revenue < 751):
            plan = "Launch Plan - $24.99/mo"
            break;
        case (revenue < 3001):
            plan = "Pro Plan - $99.99/mo"
            break;
        case (revenue < 10001):
            plan = "Advanced Plan - $199.99/mo"
            break;
        default:
            plan = "Unlimited Plan - $249.99/mo"
            break;
    }
    return plan
}
function planToPlanPrice(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $24.99/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $99.99/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.99/mo";
          break;
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $249.99/mo";
      }
    return planPrice
}
function comparePlans(plan){
    let value = 0
    switch (plan) {
      case "Pro Plan":
        value = 1;
        break;
      case "Advanced Plan":
        value = 2;
        break;
      case "Scale 1 Plan":
        value = 3;
        break;
      case "Scale 2 Plan":
        value = 4;
        break;
      case "Scale 3 Plan":
        value = 5;
        break;
      case "Scale 4 Plan":
        value = 6;
        break;
      case "Unlimited Plan":
        value = 7;
        break;
    }
    return value
  }
function currentPlan(usageCharge,shopData){
    let planDesp = shopData && shopData.subscriptionPlan ? shopData.subscriptionPlan:"Launch Plan"
    if(usageCharge){
        usageCharge.forEach(charge => {
          if(charge.id){
            if(shopData && shopData.subscriptionPlan){
                if(shopData.subscriptionPlan === planDesp || comparePlans(charge.description) > comparePlans(planDesp)){
                    
                    planDesp = charge.description
                }
            }else{
                if("Launch Plan" === planDesp || comparePlans(charge.description) > comparePlans(planDesp)){
                    planDesp = charge.description
                }
            }
          }
        })
    }
    return planDesp
}
function newRevenueToPlan(revenue) {
    let plan
    switch (true) {
        case (revenue < 501):
            plan = "Launch Plan - $39.00/mo"
            break;
        case (revenue < 3001):
            plan = "Pro Plan - $119.00/mo"
            break;
        case (revenue < 10001):
            plan = "Advanced Plan - $199.00/mo"
            break;
        default:
            plan = "Unlimited Plan - $279.00/mo"
            break;
    }
    return plan
}
function newPlanToPlanPrice(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $39.00/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $119.00/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.00/mo";
          break;
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $279.00/mo";
      }
    return planPrice
}
function newRevenueToPlan1(revenue) {
    let plan
    switch (true) {
        case (revenue < 501):
            plan = "Launch Plan - $49.00/mo"
            break;
        case (revenue < 3001):
            plan = "Pro Plan - $119.00/mo"
            break;
        case (revenue < 10001):
            plan = "Advanced Plan - $199.00/mo"
            break;
        default:
            plan = "Unlimited Plan - $299.00/mo"
            break;
    }
    return plan
}
function newPlanToPlanPrice1(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $49.00/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $119.00/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.00/mo";
          break;
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $299.00/mo";
      }
    return planPrice
}
function newPlanToPlanPrice2(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $49.00/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $119.00/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.00/mo";
          break;
        case "Scale 1 Plan":
          planPrice = "Scale 1 Plan - $299.00/mo";
          break;
        case "Scale 2 Plan":
          planPrice = "Scale 2 Plan - $399.00/mo";
          break;
        case "Scale 3 Plan":
          planPrice = "Scale 3 Plan - $499.00/mo";
          break;
        case "Scale 4 Plan":
          planPrice = "Scale 4 Plan - $699.00/mo";
          break;  
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $799.00/mo";
      }
    return planPrice
}
function Billing(props) {
  const { firebase, shop, token } = useContext(FirebaseContext);
  const revenueDocs = query(collection(firebase.firestore, 'shops', shop, 'revenues'), orderBy("createdAt", "desc"));
  const [revenues, revenuesLoading, revenuesError] = useCollection(
    revenueDocs
  );
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc
  );
  const date = new Date('2022-03-30')
  const sDate = new Date('2022-05-11')
  const oDate = new Date('2023-11-14')
  const [plan, setPlan] = useState("new")
  const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
  const currency = shopData && shopData.shopData && shopData.shopData.currency || "USD";

  useEffect(() => {
    if(shopData ){
        const appInstalledTime = new Date(shopData.createdAt.seconds * 1000)
        if(appInstalledTime < date ){
            setPlan("old")
        }else{
            if(appInstalledTime < sDate ){
                setPlan("old1")
            }else{
                if(appInstalledTime < oDate ){
                    setPlan("old2")
                }
            }
        }
    }
  }, [shopData])
  

        return (
            <Page 
                title="Billing"
                breadcrumbs={[{content: 'Settings', url: '/app/settings'}]}
            >
                <BillingWrapper>
                <Layout>
                    <Layout.Section>
                        <Card>
                            <Card.Section>
                            <Stack>
                                <Icon
                                source={CreditCardMajor}
                                color="base" /> 
                               <TextStyle variation="strong">Billing & usage charges</TextStyle>
                            </Stack>
                            </Card.Section>
                            <Card.Section>
                                <Stack>
                                    { shopData && "Launch Plan" === shopData.subscriptionPlan ?
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Launch Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>{"old" === plan ? "$24.99":"old1" === plan? "$39.00":"$49.00"}</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>{"old" === plan ? "$750":"$500" }</Subheading>
                                        </Card.Section>
                                    </Card>
                                    :
                                    false
                                    }   
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Pro Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>{"old" === plan ? "$99.99":"$119.00"}</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>$3,000</Subheading>
                                        </Card.Section>
                                    </Card>
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Advanced Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>{"old" === plan ? "$199.99":"$199.00"}</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>$10,000</Subheading>
                                        </Card.Section>
                                    </Card>
                                    { "new" === plan && 
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Scale 1 Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>$299.00</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>$15,000</Subheading>
                                        </Card.Section>
                                    </Card>
                                    }
                                    { "new" === plan && 
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Scale 2 Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>$399.00</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>$20,000</Subheading>
                                        </Card.Section>
                                    </Card>
                                    }
                                    { "new" === plan && 
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Scale 3 Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>$499.00</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>$30,000</Subheading>
                                        </Card.Section>
                                    </Card>
                                    }
                                    { "new" === plan && 
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Scale 4 Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>$699.00</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>$50,000</Subheading>
                                        </Card.Section>
                                    </Card>
                                    }
                                    <Card>
                                        <Card.Section>
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <TextStyle variation="strong">Unlimited Plan</TextStyle>
                                                </div>
                                                <Stack spacing="extraTight" distribution="center" alignment="center">
                                                    <span>USD </span>
                                                    <Heading>{"old" === plan ? "$249.99":"old1" === plan? "$279.00":"old2" === plan? "$299.00": "$799.00"}</Heading>
                                                    <span> /mon</span>
                                                </Stack>
                                            </div>
                                        </Card.Section>
                                        <Card.Section>
                                            <Subheading>EARN UP TO</Subheading>
                                            <Subheading>Unlimited</Subheading>
                                        </Card.Section>
                                    </Card>
                                </Stack>
                                {/*
                                <div className="plan-note">
                                    <span>Note: our plans are based on how much upsell revenue you earn in a month. The plan will automatically upgrade or downgrade based on your upsell revenue in a billing period.</span>
                                </div>
                                 */}
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card>
                            <Card.Section>
                                <Subheading>All Plans Include</Subheading>
                                <ul className="all-plans-include">
                                    <li> 
                                        <Stack spacing="extraTight">
                                        <Icon
                                            source={TickMinor}
                                            color="base" />
                                        <TextStyle variation="strong">Design customization</TextStyle>
                                        </Stack>
                                    </li>
                                    <li> 
                                        <Stack spacing="extraTight">
                                        <Icon
                                            source={TickMinor}
                                            color="base" />
                                        <TextStyle variation="strong">In-depth analytics</TextStyle>
                                        </Stack>
                                    </li>
                                    <li> 
                                        <Stack spacing="extraTight">
                                        <Icon
                                            source={TickMinor}
                                            color="base" />
                                        <TextStyle variation="strong">Advanced configurator with preview</TextStyle>
                                        </Stack>
                                    </li>
                                    <li> 
                                        <Stack spacing="extraTight">
                                        <Icon
                                            source={TickMinor}
                                            color="base" />
                                        <TextStyle variation="strong">Email support</TextStyle>
                                        </Stack>
                                    </li>
                                    <li> 
                                        <Stack spacing="extraTight">
                                        <Icon
                                            source={TickMinor}
                                            color="base" />
                                        <TextStyle variation="strong">Smart rule engine</TextStyle>
                                        </Stack>
                                    </li>
                                </ul>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card>
                            <Card.Section>
                                <Subheading>Your metrics</Subheading>
                                <div className="metrics">
                                {revenues && revenues.size > 0 ?
                                <DescriptionList
                                    items={[
                                        {
                                        term: 'Current plan',
                                        description:"old" === plan ? 
                                         planToPlanPrice(revenues.docs[0].data().usageCharge,shopData)
                                         :
                                            "old1" === plan? 
                                            newPlanToPlanPrice(revenues.docs[0].data().usageCharge,shopData)
                                            :
                                            "old2" === plan? 
                                            newPlanToPlanPrice1(revenues.docs[0].data().usageCharge,shopData)
                                            :
                                            newPlanToPlanPrice2(revenues.docs[0].data().usageCharge,shopData),
                                        },
                                        {
                                        term: 'All time upsell revenue',
                                        description:formatCurrency(revenues.docs.reduce((memo, d) => memo + d.data().revenue, 0), locale, 'USD'),
                                        },
                                    ]}
                                    spacing="tight"
                                />
                                :
                                <DescriptionList
                                    items={[
                                        {
                                        term: 'Upsell revenue this billing period',
                                        description:
                                            '$0',
                                        },
                                        {
                                        term: 'Current plan',
                                        description:
                                        "old" === plan ? 'Launch Plan - $24.99/mo':"old1" === plan? 'Launch Plan - $39.00/mo': shopData && "Launch Plan" === shopData.subscriptionPlan ? 'Launch Plan - $49.00/mo':'Pro Plan - $119.00/mo',
                                        },
                                        {
                                        term: 'All time upsell revenue',
                                        description:
                                            '$0',
                                        },
                                    ]}
                                    spacing="tight"
                                />
                                }
                                </div>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                </Layout>
                </BillingWrapper>
            </Page>
        )
    
}

export default Billing
